.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.items-center {
  align-items: center;
}
.gap-10 {
  gap: 10px;
}
.gap-x-10 {
  column-gap: 10px;
}
.gap-y-10 {
  row-gap: 10px;
}
.gap-15 {
  gap: 15px;
}
.gap-x-15 {
  column-gap: 15px;
}
.gap-y-15 {
  row-gap: 15px;
}
.gap-26 {
  gap: 26px;
}
.gap-x-26 {
  column-gap: 26px;
}
.gap-y-26 {
  row-gap: 26px;
}
.gap-52 {
  gap: 52px;
}
.gap-x-52 {
  column-gap: 52px;
}
.gap-y-52 {
  row-gap: 52px;
}
ul.reset-list,
ol.reset-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.uppercase {
  text-transform: uppercase;
}
